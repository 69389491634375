import http from "../request";
import { EntityListData } from "@/api";
import { H5_PREFIX } from "@/config";

export interface MealProduct {
  id: number;
  meal_date: string; // 订餐日期
  meal_time: string; // 订餐时间
  name: string; // 餐品名称
  img: string; // 图片
  price: number; // 价格
  create_time: string;
  update_time: string;
}

export interface MealProductShow extends MealProduct {
  count?: number; // 订餐数量
}

const baseUrl = `${H5_PREFIX}/meal-product`;

export interface MealProductApi {
  getTotalMealProduct: (query?: string) => Promise<MealProduct[]>;
  getMealProductById: (id: number) => Promise<MealProduct>;
}

const mealProductApi: MealProductApi = {
  getTotalMealProduct(query) {
    const url = `${baseUrl}?pageSize=1&pageSize=9999${query || ""}`;
    return http
      .get<EntityListData<MealProduct>>(url)
      .then((res) => res.data.data);
  },
  getMealProductById(id) {
    const url = `${baseUrl}/${id}`;
    return http.get<MealProduct>(url).then((res) => res.data);
  },
};

export default mealProductApi;
