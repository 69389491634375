import http from "../request";
import { H_UID_KEY, H_INFO_KEY } from "@/common/storage";
import { H5_PREFIX } from "@/config";
import store from "@/store";

export interface HUser {
  id: number;
  email: string; // 邮箱
  password: string; // 密码
  user_name: string; // 姓名
  mobile: string; // 联系方式
  create_time: string;
  update_time: string;
}

export type HUserData = Pick<
  HUser,
  "user_name" | "mobile" | "email" | "password"
>;

const baseUrl = `${H5_PREFIX}/h-user`;

export interface HUserApi {
  getHUser: () => Promise<HUser>;
  postHUser: (data: HUserData) => Promise<HUser>;
  updateHUser: (id: number, data: HUserData) => Promise<HUser>;
}

const hUserApi: HUserApi = {
  getHUser() {
    const uid = localStorage.getItem(H_UID_KEY);
    return http.get<HUser>(`${baseUrl}/${uid}`).then((res) => res.data);
  },
  postHUser(data) {
    return http.post<HUser>(baseUrl, data).then((res) => res.data);
  },
  updateHUser(id, data) {
    return http.patch<HUser>(`${baseUrl}/${id}`, data).then((res) => {
      const user = res.data;
      store.commit("setHUser", user);
      localStorage.setItem(H_INFO_KEY, JSON.stringify(user));
      return user;
    });
  },
};

export default hUserApi;
