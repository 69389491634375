import { clearStorage } from "@/common/storage";
import { APP_ID } from "@/config";
import { Modal } from "@/ui";
import lang from "@/components/lang-switch/i18n";
import router from "@/router";

let loading = false;

const auth = function (state?: string) {
  if (loading) return;
  loading = true;
  clearStorage();
  const redirect_uri = encodeURIComponent(
    `${location.protocol}//${location.host}/auth`
  );
  state = state || location.pathname + location.search;
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
  location.replace(url);
  // location.href = url;
};

export const showAuth = function (force?: boolean) {
  Modal({
    title: lang.value.prompts,
    content: lang.value.authTip,
    confirmBtn: lang.value.auth,
    cancelBtn: force ? false : lang.value.cancel,
    onConfirm: () => {
      auth();
    },
  });
};

export const showIpccAuth = function (force?: boolean) {
  Modal({
    title: lang.value.prompts,
    content: lang.value.loginTip,
    confirmBtn: lang.value.login,
    cancelBtn: force ? false : lang.value.cancel,
    onConfirm: () => {
      router.push({
        name: "ipccLogin",
        query: { redirect: encodeURIComponent(window.location.pathname) },
      });
    },
  });
};

export default auth;
