import { createStore, StoreOptions } from "vuex";
import { RootState } from "./type";
import user from "./modules/user";
import route from "./modules/route";
import entity from "./modules/entity";
import reception from "./modules/reception";
import riefing from "./modules/riefing";
import ipcc from "./modules/ipcc";
import hUser from "./modules/hUser";

const storeOptions: StoreOptions<RootState> = {
  modules: {
    user,
    route,
    entity,
    reception,
    riefing,
    ipcc,
    hUser,
  },
};
export default createStore<RootState>(storeOptions);
