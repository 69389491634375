import { Lang } from "@/store/modules/reception";

export interface LangText {
  [key: string]: string;
}
export type LangTextMap = {
  [key in Lang]: LangText;
};

const testMap: LangTextMap = {
  zh: {
    colon: "：",
    prompts: "提示",
    confirm: "确定",
    auth: "授权",
    cancel: "取消",
    authTip: "您尚未登录，请先登录授权！",
    home: "首页",
    user: "微信用户",
    unAuth: "未登录，点击授权登录",
    center: "个人中心",
    info: "个人信息",
    journey: "行程安排",
    meal: "用餐安排",
    stay: "住宿安排",
    searching: "查询中...",
    mealInfo: "用餐信息",
    mealTime: "用餐时间",
    hotelName: "酒店名称",
    hotelAddress: "酒店地址",
    mealPlace: "用餐地点",
    mealEmpty: "暂无用餐安排",
    stayInfo: "住宿信息",
    roomType: "房型",
    stayEmpty: "暂无住宿信息",
    journeyInfo: "行程安排",
    arrivalInfo: "到达信息",
    arrivalType: "到达方式",
    arrivalNumber: "航班车次",
    carNumber: "车牌号",
    arrivalTime: "到达时间",
    pickAddress: "接站地址",
    departuresInfo: "离开信息",
    departuresType: "离开方式",
    departuresTime: "离开时间",
    vehicleInfo: "车辆安排",
    dispatchTime: "派车时间",
    driverName: "司机姓名",
    contactNumber: "联系电话",
    receptionist: "接待人员",
    name: "姓名",
    mobile: "手机号",
    sex: "性别",
    unset: "未设置",
    save: "保存",
    edit: "编辑",
    submit: "提交",
    submitTip: "确定提交吗？",
    submitSuccess: "提交成功",
    idCardError: "身份证号码格式不正确",
    riefingTitle: "国际会议外事通",
    wstLink: "点击查看官网",
    signIn: "签到",
    agenda: "会议议程",
    cloudPhoto: "云相册",
    namePlaceholder: "请输入姓名",
    mobilePlaceholder: "请输入手机号",
    sexPlaceholder: "请选择性别",
    registerTip: "未查询到该人员，请联系工作人员",
    hasSign: "您已签到",
    signSuccess: "签到成功",
    signFail: "签到失败",
    guestQueryFail: "嘉宾查询失败",
    signTip: "请凭此界面在签到处获取入场手环",
    signTipWithTicket: "请凭此界面在签到处获取入场手环与新年音乐会门票",
    screenshotTip: "（需要此截图界面，请不要退出）",
    male: "男",
    female: "女",
    hasRegister: "您已注册",
    hasRegisterSign: "您已注册签到",
    registerSuccess: "注册签到成功",
    registerFail: "注册失败",
    registerSign: "注册签到",
    introduce: "杭州市情介绍",
    weather: "智能天气",
    guide: "交通指引",
    meeting: "会议安排",
    layout: "会场布局",
    service: "功能区服务",
    seat: "座位查询",
    food: "订餐服务",
    culture: "文旅服务",
    scenery: "杭州著名\n景点介绍",
    product: "产品介绍",
    currency: "货币",
    voltage: "电压",
    tel: "紧急电话",

    login: "登录",
    userCenter: "用户",
    loginTip: "您尚未登录，请前往登录页登录！",

    historyEvolution: "历史沿革",
    physicalGeography: "自然地理",
    administrativeArea: "行政区域",
    historyCulture: "历史文化",
    famousProduct: "名优特产",
    job: "人口就业",

    locationLabel: "位置：",
    contentLabel: "服务内容：",
    timeLabel: "服务时间：",

    serviceTitle: "功能区位置介绍",
    networkTitle: "网络通信办理处",
    materialTitle: "注册及资料领取处",
    healthTitle: "医疗服务点",
    infoTitle: "洲际酒店咨询台",

    inauguration: "开幕式会场",
    network: "网络通信办理处",
    material: "注册及资料领取处",
    health: "医疗服务点",
    information: "咨询台",

    networkLocation: "洲际酒店1号门内，注册处左侧",
    networkContent: "电话卡办理（嘉宾自费），网络卡发放（免费）",
    networkTime1: "2025年2月23日 14:00-18:00",
    networkTime2: "2025年2月24日-3月1日 08:00-17:00",

    materialLocation: "洲际酒店杭州厅裙廊",
    materialContent: "会议签到注册、会议材料领取",
    materialTime1: "2025年2月23日 14:00-22:00",
    materialTime2: "2025年2月24日-3月1日 08:00-20:00",
    materialRegisterTime: "注册时间：2月23日14:00开始",

    healthLocation: "洲际酒店一楼贵宾休息室3",
    healthContent:
      "患者突发疾病、意外伤害等紧急医疗急救等（不包含病情稳定常见病、多发病的诊疗活动）",
    healthTime: "2025年2月24日-3月1日 09:00-19:00",

    infoLocation: "洲际酒店杭州厅裙廊",
    infoContent:
      "外事通咨询服务、嘉宾用餐、出行咨询引导服务、大会相关信息解答、转换插头售卖",
    infoTime1: "2025年2月23日 14:00-18:00",
    infoTime2: "2025年2月24日-3月1日 08:00-会议结束",

    layoutTitle: "会场布局",
    openingCeremonyLabel: "开幕式：",
    breakoutRoomLabel1: "分组讨论室1：",
    breakoutRoomLabel2: "分组讨论室2：",
    privateMeetingRoom: "小型会议室：",
    openingCeremony: "杭州厅1、2、3、4（主会场）",
    breakoutRoom1: "杭州厅5、6、7",
    breakoutRoom2: "上海厅",
    privateMeeting: "重庆厅",

    guideTitle: "交通指引",
    guideTitleText1: "主会场、机场、高铁站",
    guideTitleText2: "配套酒店",
    guideMethodText: "通用交通方式",
    HangzhouInternationalHotel: "杭州国际会议中心洲际酒店",
    HangzhouXiaoshanAirport: "杭州萧山机场",
    HangzhouEastRailwayStation: "杭州火车东站",
    supportingReceptionHotel: "配套接待酒店",

    guideMethodWay1: "建议使用手机自带地图进行导航。",
    guideMethodWay2:
      "中国大陆地区常用导航为高德地图：可导航至目的地、搜索周边美食、查看景点路线等。",
    guideMethodWay3:
      "在市民中心地铁站可跟随图中指示前往杭州国际会议中心洲际酒店",

    guideWayFirstTitle1: "推荐方案一 ：地铁出行",
    guideWayFirstText1: "运营时间：05:50–00:27",
    guideWayFirstTitle2: "推荐方案二 ：出租车出行",
    guideWayFirstText2: "T3上客区:位于T3航站楼到达层14号门外",
    guideWayFirstText3: "T4上客区:位于T4航站楼与交通中心F1层连廊处",

    guideWaySecondTitle1: "推荐方案一 ：地铁出行",
    guideWaySecondText1: "运营时间：06:02–23:11",
    guideWaySecondTitle2: "推荐方案二 ：出租车出行",
    guideWaySecondText2: "杭州东站分别设置了出租车等候区(南)和出租车等候区(北)",
  },
  en: {
    colon: ":",
    prompts: "Prompts",
    confirm: "Confirm",
    auth: "Authorize",
    cancel: "Cancel",
    authTip: "Please sign in first!",
    home: "HOME",
    center: "PERSONAL CENTER",
    user: "WeChat User",
    unAuth: "Sign In",
    info: "Personal Information",
    journey: "Itinerary",
    meal: "Catering",
    stay: "Accommodations",
    searching: "Querying...",
    mealInfo: "Catering Facts",
    mealTime: "Dining Time",
    hotelName: "Hotel",
    hotelAddress: "Hotel Address",
    mealPlace: "Dining Place",
    mealEmpty: "No Data",
    stayInfo: "Accommodations Facts",
    roomType: "Room Type",
    stayEmpty: "No Data",
    journeyInfo: "Schedule",
    arrivalInfo: "Arrivals",
    arrivalType: "Means of Transport",
    arrivalNumber: "Flight/Train Number",
    carNumber: "Car Plate Number",
    arrivalTime: "Time of Arrival",
    pickAddress: "Place of Pick Up",
    departuresInfo: "Departures",
    departuresType: "Means of Transport",
    departuresTime: "Time of Departure",
    vehicleInfo: "Transport",
    dispatchTime: "Dispatch Time",
    driverName: "Driver",
    contactNumber: "Contact Number",
    receptionist: "Guide",
    name: "Name",
    mobile: "Mobile Phone Number",
    sex: "Sex",
    unset: "Not Set",
    save: "Save",
    edit: "Edit",
    submit: "Submit",
    submitTip: "Are you sure to submit?",
    submitSuccess: "Data Submitted Successfully",
    idCardError: "ID Card Number Error",
    riefingTitle: "GLOBAL MICE",
    wstLink: "Click to view the official website",
    signIn: "Sign in",
    agenda: "Agenda",
    cloudPhoto: "Photo Stream",
    namePlaceholder: "Please enter your name",
    mobilePlaceholder: "Please enter your phone number",
    sexPlaceholder: "Please select your gender",
    registerTip:
      "Sign-in information not found. Please contact staff for registration",
    signSuccess: "Sign-in completed",
    signFail: "Sign-in failed",
    guestQueryFail: "Query guest failed",
    hasSign: "Sign-in completed",
    signTip: "Please collect your wristband at the registration desk",
    signTipWithTicket:
      "Please collect your wristband and ticket for the BRLC New Year’s Concert at the registration desk",
    screenshotTip: "(sign-in screenshot required, please don’t quit)",
    male: "Male",
    female: "Female",
    hasRegister: "You’ve been registered",
    hasRegisterSign: "You’ve been registered (signed in)",
    registerSuccess: "Registration (sign-in) completed",
    registerFail: "Registration failed",
    registerSign: "Register (Sign in)",
    introduce: "Introduction to\nHangzhou",
    weather: "Smart\nMeteorology",
    guide: "Transportation\nGuide",
    meeting: "Conference\nSchedule",
    layout: "Venue\nLayout",
    service: "Functional\nArea Service",
    seat: "Seat Inquiry",
    food: "Meal Ordering\nService",
    culture: "Cultural and\nTourism Services",

    login: "login",
    userCenter: "user",
    loginTip:
      "You have not logged in yet. Please go to the login page to log in",

    historyEvolution: "History",
    physicalGeography: "Geography",
    administrativeArea: "Administrative\nDivisions",
    historyCulture: "History and\nCulture",
    famousProduct: "Brand Local\nSpecialties",
    job: "Population and\nEmployment",

    locationLabel: "Location: ",
    contentLabel: "Services Offered: ",
    timeLabel: "Service Hours:",

    serviceTitle: "Locations of\nFunctional Areas",
    networkTitle: "SIM Card and\nInternet Service",
    materialTitle: "Conference Registration\nand Material  Collection Desk",
    healthTitle: "Medical Service",
    infoTitle: "Information Desk at\nInterContinental Hangzhou",

    inauguration: "Opening Ceremony\nVenue",
    network: "SlM card and\nInternet service",
    material: "Registration and\nMaterialCollection Desk",
    health: "Medical Service",
    information: "Information Desk",

    networkLocation:
      "To the left of the registration, opposite the Cultural and Creative Products Desk at Gate 1",
    networkContent:
      "SIM card-related services (at guest's own expense), and distribution of barrier-free Internet access SIM cards(free of charge)",
    networkTime1: "February 23, 2025, 14:00-18:00 ",
    networkTime2: "February 24 - March 1,2025, 08:00-17:00",

    materialLocation: "Inside Gate 1 of InterContinental  Hangzhou ICC",
    materialContent:
      "Conference check-in and registration, and conference material collection",
    materialTime1: "23rd February:14:00-22:00",
    materialTime2: "February 24 - March 1,2025, 08:00-20:00",
    materialRegisterTime: "Starting from 14:00, Feb 23",

    healthLocation: "VIP Lounge 3, 1st Floor, InterContinental Hangzhou ICC",
    healthContent:
      "Emergency medical rescue for patients with sudden illnesses, accidental injuries, etc. (excluding diagnoses and treatments for stable, common, or frequently occurring diseases).",
    healthTime: "February 24 - March 1,2025, 09:00-19:00",

    infoLocation:
      "Skirt Corridor of Hangzhou Hall of InterContinental Hangzhou ICC",
    infoContent:
      "Foreign affairs consultation services, guest diningassistance, travel consultation and guidance, answers to conference-related inquiries, sale of power adapters.",
    nfoTime1: "February 23, 2025, 14:00-18:00",
    infoTime2:
      "February 24-March 1, 2025, 08:00-until the end of the conference",

    layoutTitle: "Venue Layout",
    openingCeremonyLabel: "Opening Ceremony：",
    breakoutRoomLabel1: "Breakout Room 1：",
    breakoutRoomLabel2: "Breakout Room 2：",
    privateMeetingRoom: "Private Meeting Room：",
    openingCeremony: "Hangzhou Hall 1, 2, 3, 4 (Main Conference Room)",
    breakoutRoom1: "Hangzhou Hall 5, 6, 7",
    breakoutRoom2: "Shanghai Hall",
    privateMeeting: "Chongqing Hall",

    guideTitle: "Transportation Guide",
    guideTitleText1: "Main venue、airport、high-speed railway station",
    guideTitleText2: "Supporting hotels",
    guideMethodText: "General\nTransportation Options",
    HangzhouInternationalHotel: "InterContinental Hangzhou ICC",
    HangzhouXiaoshanAirport: "Hangzhou International Airport",
    HangzhouEastRailwayStation: "Hangzhoudong Railway Station",
    supportingReceptionHotel: "Supporting Hotels",

    guideMethodWay1:
      "It is recommended to use the built-in map app on your phone for navigation.",
    guideMethodWay2:
      "The most commonly used navigation app in Chinese mainland is GaodeMap: It can be used to navigate to destinations, search for nearby food,check routes to tourist spots, etc.",
    guideMethodWay3:
      "While at the Citizen Center Metro Station, you can follow the directions in the picture to go to the InterContinental Hangzhou ICC.",

    guideWayFirstTitle1: "Recommended Option 1: Travel by Metro",
    guideWayFirstText1: "Operating hours: 05:50 - 00:27",
    guideWayFirstTitle2: "Recommended Option 2: Travel by Taxi",
    guideWayFirstText2:
      "T3 Pickup Area:\nLocated outside Gate 14 on the arrival level of Terminal 3.",
    guideWayFirstText3:
      "T4 Pickup Area:\nLocated at the corridor connecting Terminal 4 and Floor F1 of the Transportation Hub.",

    guideWaySecondTitle1: "Recommended Option 1: Travel by Metro",
    guideWaySecondText1: "Operating hours: 06:02 - 23:11",
    guideWaySecondTitle2: "Recommended Option 2: Travel by Taxi",
    guideWaySecondText2:
      "Hangzhoudong Railway Station has two taxi waiting areas (South and North).",
  },
};

export default testMap;
