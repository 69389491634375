import { MealProductShow } from "@/api";
import { IpccState } from "@/store/type";

export default {
  state: {
    order: null,
  },
  getters: {
    order: (state: IpccState) => state.order,
  },
  mutations: {
    setOrder(state: IpccState, value: IpccState["order"]) {
      state.order = value;
    },
    updateOrder(state: IpccState, value: MealProductShow) {
      const order = state.order;
      if (order) {
        const index = order.findIndex(
          (item: MealProductShow) => item.id === value.id
        );
        if (index === -1) {
          value.count && order.push(value);
        } else {
          if (value.count) {
            // 替换更新
            order.splice(index, 1, value);
          } else {
            // 删除
            order.splice(index, 1);
          }
        }
      } else {
        value.count && (state.order = [value]);
      }
    },
  },
};
