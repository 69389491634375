import { HUser } from "@/api/modules/hUser";
import { RootState, HUserState } from "@/store/type";
import { ActionContext } from "vuex";
import { AuthToken, getToken, H_TOKEN_KEY } from "@/common/storage";
import apiPlugin from "@/api";

export default {
  state: {
    hToken: null,
    hUser: null,
    hUid: null,
  },
  getters: {
    hToken: (state: HUserState) => state.hToken,
    hUser: (state: HUserState) => state.hUser,
    hUid: (state: HUserState) => state.hUid,
  },
  mutations: {
    setHToken(state: HUserState, value: AuthToken | null) {
      state.hToken = value;
    },
    setHUser(state: HUserState, value: HUser | null) {
      state.hUser = value;
    },
    setHUid(state: HUserState, value: number | null) {
      state.hUid = value;
    },
  },
  actions: {
    setHUserState(context: ActionContext<HUserState, RootState>) {
      const hToken = getToken(H_TOKEN_KEY);
      console.log("set hUser state.");
      if (hToken) {
        context.commit("setHToken", hToken);
        return apiPlugin.getHUser().then((res) => {
          context.commit("setHUser", res);
          context.commit("setHUid", res.id);
        });
      } else {
        return Promise.reject({ error: 1 });
      }
    },
  },
};
