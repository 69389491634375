import { defineComponent } from "vue";
import InputProps from "./props";
import mixin from "../form/mixin";
import "./style/input.less";

export default defineComponent({
  name: "CInput",
  props: { ...InputProps },
  mixins: [mixin],
  computed: {
    inputAttrs() {
      return Object.assign(
        {},
        this.$attrs,
        // 或覆写一些监听器的行为
        {
          // 这里确保组件配合 `v-model` 的工作
          onInput: (e: Event): void => {
            const value = (e.target as HTMLInputElement).value;
            this.$emit("update:modelValue", value);
            this.notifyCheck(true);
          },
          onBlur: (e: Event): void => {
            this.$emit("blur", e);
            this.notifyCheck();
          },
          value: this.modelValue,
        }
      );
    },
  },
  data() {
    return {};
  },
  render() {
    const type = this.type;
    return (
      <div class="c-input-box">
        {type === "textarea" ? (
          <textarea {...this.inputAttrs} class="c-text-area" />
        ) : (
          <input {...this.inputAttrs} type={type} class="c-input" />
        )}
      </div>
    );
  },
});
