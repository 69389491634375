import { App, Plugin } from "vue";
import articleApi, { ArticleApi } from "./modules/article";
import uploadApi, { UploadApi } from "./modules/upload";
import userApi, { UserApi } from "./modules/user";
import receptionApi, { ReceptionApi } from "./modules/reception";
import guestApi, { GuestApi } from "./modules/guest";
import dinnerAllocationApi, {
  DinnerAllocationApi,
} from "./modules/dinnerAllocation";
import hotelAllocationApi, {
  HotelAllocationApi,
} from "./modules/hotelAllocation";
import vehicleAllocationApi, {
  VehicleAllocationApi,
} from "./modules/vehicleAllocation";
import enrollApi, { EnrollApi } from "./modules/enroll";
import mealProductApi, { MealProductApi } from "./modules/mealProduct";
import hUserApi, { HUserApi } from "./modules/hUser";

export type ApiPlugin = Plugin &
  ArticleApi &
  UploadApi &
  UserApi &
  ReceptionApi &
  GuestApi &
  DinnerAllocationApi &
  HotelAllocationApi &
  VehicleAllocationApi &
  EnrollApi &
  MealProductApi &
  HUserApi;
const apiPlugin: ApiPlugin = {
  install(app: App) {
    app.config.globalProperties.$api = apiPlugin;
  },
  ...articleApi,
  ...uploadApi,
  ...userApi,
  ...receptionApi,
  ...guestApi,
  ...dinnerAllocationApi,
  ...hotelAllocationApi,
  ...vehicleAllocationApi,
  ...enrollApi,
  ...mealProductApi,
  ...hUserApi,
};
export default apiPlugin;
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $api: ApiPlugin;
  }
}
export * from "./type";
